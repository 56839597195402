import Dexie from 'dexie';

export const db = new Dexie('TaskOrbiter');

db.version(8).stores({
    items: 'id',
    favorites: 'item_id',
    lists: 'id',
    users: 'id',
    myTasks: 'item_id',
    topics: 'id',
    statuses: 'member_id'
});